<template>
  <div
    class="social-space-card clickable-item position-relative"
    :class="{ 'reorder-handle': isReorderable, 'd-flex': !forFeed, vertical }"
    @click="openCard(socialSpace)"
    @contextmenu="handleContext"
  >
    <JoinSocialSpace
      :isOpen="isFormOpen"
      :modalTitle="modalTitle"
      :name="get(socialSpace, 'name')"
      :socialspaceId="socialSpace.id"
      @dismissModal="isFormOpen = false"
      @status="checkStatus"
    />
    <div class="wrp" :style="forFeed ? 'width: 100%' : 'width: 50%'">
      <a :href="spaceHref" @click.prevent="goToSpace(socialSpace)">
        <div class="image position-relative d-flex align-items-center">
          <div class="tags-wrap position-absolute">
            <div class="tag d-flex align-items-center justify-content-center">
              {{
                ['updated_social_world_text', 'updated_world_profile_img'].includes(feed.action)
                  ? 'Social Space Update'
                  : feed.action === 'created_social_space_world'
                  ? 'New Social Space'
                  : 'Social Space'
              }}
            </div>
            <MatureTag class="mt-1" v-if="isNsfw" />
          </div>

          <div v-if="socialSpace.privacy === 'M'" class="private position-absolute">🔒</div>

          <img loading="lazy" v-world-card-image class="img" :src="resizeUpload(socialSpace.cover_image, '800w')" />
          <div
            v-if="isOwner && showOwnerIcon"
            class="px-2 owner-tag d-flex align-items-center justify-content-center position-absolute"
          >
            <inline-svg class="icon mr-1" src="/icons/socialSpaceOwner.svg" width="12px" height="12px" /> ADMIN
          </div>
        </div>
      </a>
    </div>
    <div
      class="d-flex flex-column flex-grow-1 wrp-2"
      :class="{ 'ml-2': !forFeed }"
      :style="forFeed ? 'width: 100%' : 'width: 50%'"
    >
      <div
        @click.prevent="goToSpace(socialSpace)"
        class="name"
        :class="{ 'my-2': forFeed }"
        :style="forFeed ? 'font-size: 12px' : ''"
      >
        <span v-if="['updated_social_world_text', 'updated_world_profile_img'].includes(feed.action)">
          <span style="color: #7ad7ff">
            {{ text }}
          </span>
          update on
        </span>
        <span
          :style="
            ['updated_social_world_text', 'updated_world_profile_img'].includes(feed.action)
              ? 'text-decoration: underline; color: #7ad7ff'
              : ''
          "
        >
          {{ socialSpace.name }}
        </span>
        <span v-if="['updated_social_world_text', 'updated_world_profile_img'].includes(feed.action)"
          >! Go check it out!
        </span>
      </div>
      <div
        class="description mt-1"
        v-if="!['updated_social_world_text', 'updated_world_profile_img'].includes(feed.action)"
      >
        {{ truncate(stripHtmlTags(socialSpace.description), { length: 45 }) }}
      </div>
      <div class="lower mt-auto">
        <div class="actions mt-auto d-flex" :class="{ 'justify-content-between': forFeed }">
          <Reaction
            v-if="!limited"
            type="world"
            class="position-relative"
            :showBox="get(socialSpace, 'showBox')"
            :progressId="get(socialSpace, 'progressId')"
            @updated="emits('updated')"
            @delete="deleteCurrGiftBox"
            :reactions="mutatedReactionsData.reaction_counts"
            :user-reaction="mutatedReactionsData.user_reaction"
            @click.stop
            @changed="(reaction) => reactionChanged(reaction.key)"
          >
            <ion-button color="transparent" class="inline-button icon-button clickable d-file">
              <div class="d-flex align-items-center justify-content-center">
                <i
                  class="text-primary mr-1 ti-thumb-up"
                  :class="{
                    'text-secondary': !!mutatedReactionsData?.user_reaction,
                  }"
                />
                <span
                  class="reaction-count"
                  :class="[!!mutatedReactionsData?.user_reaction ? 'text-secondary' : 'text-primary']"
                  >{{ mutatedReactionsData.reaction_counts?.total_count || 0 }}</span
                >
              </div>
            </ion-button>
          </Reaction>
          <ion-button color="transparent" class="inline-button icon-button ml-2">
            <div class="d-flex align-items-center justify-content-center">
              <ion-icon class="text-primary mr-1 user-icon" :icon="peopleOutline" />
              <span color=" #3dc2ff" class="reaction-count text-primary">{{ memberCount || 0 }}</span>
            </div>
          </ion-button>

          <ion-button color="transparent" class="inline-button icon-button ml-2">
            <div class="d-flex align-items-center justify-content-center">
              <div class="chat text-black">
                <i class="ti-comments" />
              </div>
              <span color=" #3dc2ff" class="reaction-count text-black ml-1">{{ roomCount || 0 }}</span>
            </div>
          </ion-button>
        </div>
        <ion-button
          v-if="get(user, 'is_email_verified', true)"
          class="join-btn mt-auto"
          :class="{ joined, requested }"
          :disabled="joined || requested"
          @click.stop="joinSocialSpace"
        >
          {{ buttonContent }}
        </ion-button>
        <ion-button
          v-else
          class="join-btn mt-auto"
          :class="{ joined, requested }"
          :disabled="joined || requested"
          @click.stop="openVerificationModal"
        >
          {{ buttonContent }}
        </ion-button>
      </div>
    </div>
    <div style="width: 6%">
      <div
        v-if="isOwner && !limited"
        class="clickable drop-down d-flex align-items-center justify-content-center position-absolute"
        :class="{ 'drp-dwn-rg': forFeed }"
        @click.stop.prevent="openDropDown"
      >
        <i class="ti-more-alt" />
      </div>
    </div>
    <VerificationModal :is-open="verificationModal" @dismiss-modal="closeVerificationModal" />
  </div>
</template>

<script lang="ts" setup>
import { peopleOutline } from 'ionicons/icons';
import { SocialSpace } from '@/shared/types/static-types';
import MatureTag from '@/shared/components/MatureTag.vue';
import { getMemberstatus } from '@/shared/actions/worlds';
import { react } from '@/shared/helpers/worlds';
import VerificationModal from '@/shared/modals/VerificationModal.vue';
import CardPopover from './popovers/CardPopover.vue';
import { popovers } from '@/shared/native/popovers';
import { authStore } from '@/shared/pinia-store/auth';
import { resizeUpload } from '@/shared/utils/upload';
import { stripHtmlTags } from '@/shared/utils/string';
import Reaction from '@/shared/components/Reaction/index.vue';
import { isMobileFn } from '@/apps/mobile/native/device';
import JoinSocialSpace from '@/shared/modals/JoinSocialSpace.vue';

const { isAuthenticated, user } = authStore();
const isFormOpen = ref(false);

const joinStatus = ref({ status: '', role: '' });

const modalTitle = computed(() => {
  return 'Join ' + get(socialSpace.value, 'name', '');
});

const checkStatus = (status: { status: string; role: string }) => {
  joinStatus.value = status;
};

const props = defineProps({
  openBlank: {
    type: Boolean,
    default: false,
  },
  lazyUserReaction: {
    type: Boolean,
    default: false,
  },
  socialSpace: {
    type: Object,
    default: () => ({}),
  },
  showOwnerIcon: {
    type: Boolean,
  },
  limited: {
    type: Boolean,
  },
  isReorderable: {
    type: Boolean,
  },
  feed: {
    type: Object,
    default: () => ({}),
  },
  forFeed: {
    type: Boolean,
  },
  vertical: {
    type: Boolean,
  },
  memberCount: {
    type: Number,
    default: 0,
  },
  roomCount: {
    type: Number,
    default: 0,
  },
  reactedSpaces: {
    type: Object,
    default: null,
  },
});

const emits = defineEmits(['updated', 'delete']);
const socialSpace = computed(() => props.socialSpace as SocialSpace);
const limited = computed(() => props.limited as boolean);
const memberStatus = ref('');
const reactedSpaces = toRef(props, 'reactedSpaces');
const feed = toRef(props, 'feed');
const lazyUserReaction = toRef(props, 'lazyUserReaction');
const openBlank = toRef(props, 'openBlank');
const isNsfw = computed(() => socialSpace.value.is_nsfw);
const isReorderable = computed(() => props.isReorderable as boolean);
const verificationModal = ref(false);

const reactionCount = ref(socialSpace.value.reaction_counts);

const mutatedReactionsData = lazyUserReaction.value
  ? ref({
      reaction_counts: reactionCount.value,
      user_reaction: reactedSpaces.value || null,
    })
  : ref(pick(socialSpace.value, ['reaction_counts', 'user_reaction']));

watch([reactionCount, reactedSpaces], () => {
  if (lazyUserReaction.value) {
    mutatedReactionsData.value = {
      reaction_counts: reactionCount.value,
      user_reaction: reactedSpaces.value,
    };
  }
});
const id = computed(() => socialSpace.value?.id);
const deleteCurrGiftBox = () => {
  emits('delete', id.value);
};
const openVerificationModal = () => {
  verificationModal.value = true;
};

const closeVerificationModal = () => {
  verificationModal.value = false;
};

const handleContext = (e: any) => {
  if (props.isReorderable) return e.preventDefault();
};

const spaceHref = computed(() => {
  const router = useRouter();

  const route = { name: 'social-space-details', params: { slug: get(socialSpace.value, 'slug') } };

  const { href } = router.resolve(route);

  return href;
});

const goToSpace = async (space: SocialSpace) => {
  if (limited.value || isReorderable.value) return;

  const router = useRouter();

  const route = { name: 'social-space-details', params: { slug: get(space, 'slug') } };

  const isMobile = await isMobileFn();

  if (openBlank.value && !isMobile) {
    const { href } = router.resolve(route);
    window.open(href, '_blank');
    return;
  }

  router.push(route);
};

const text = computed(() => {
  if (feed.value.action === 'created_social_space_world') return;
  return `${
    feed.value.action === 'updated_social_world_text'
      ? 'Key Info'
      : feed.value.action === 'updated_world_profile_img'
      ? 'Profile Image'
      : ''
  }`;
});

const reactionChanged = async (reaction: string, isInstant = false) => {
  const reactionResp = await react(
    reaction,
    socialSpace.value,
    mutatedReactionsData.value.user_reaction,
    mutatedReactionsData.value.reaction_counts,
    isInstant
  );

  mutatedReactionsData.value = {
    reaction_counts: reactionResp.reaction_counts,
    user_reaction: reactionResp.user_reaction,
  };
};

const openDropDown = async (ev: CustomEvent) => {
  popovers.open(ev, CardPopover, { title: 'Social Space', id: id.value, value: 'world' });
};

const openCard = async (space: any) => {
  const isMobile = await isMobileFn();
  if (openBlank.value && !isMobile) return;

  const router = useRouter();
  router.push({ name: 'social-space-details', params: { slug: space.slug }, query: { tab: 'information' } });
};

const buttonContent = computed(() =>
  joined.value ? 'Joined Social Space' : requested.value ? 'Requested to join' : 'Join Social Space'
);

const joinSocialSpace = () => {
  isFormOpen.value = true;
};

onMounted(async () => {
  const reqs = [];
  if (isAuthenticated.value) {
    reqs.push(getMemberstatus({ world_id: socialSpace.value.id }));
  }

  const results = await Promise.all(reqs);
  const statusResult = isAuthenticated.value ? results.shift() : null;
  memberStatus.value = statusResult ? statusResult.status : '';
});

const joined = computed(() => memberStatus.value === 'member');
const requested = computed(() => memberStatus.value === 'requested');

const isOwner = computed(() => socialSpace.value.user?.username === user.value.username);
</script>

<style lang="sass" scoped>
.wrp
  min-width: 50%
.wrp-2
  width: 100% !important
.drp-dwn-rg
  right: 13px !important
.drop-down
  position: absolute
  z-index: 2
  right: 6px
  width: 20px
  height: 14px
  top: 14px
  color: #fff
  background: #0A0928E5
  font-size: 12px
  border-radius: 6px
  @media(max-width:280px)
    top: 34px !important
.tags-wrap
  top: 0
.private
  top: 0
  right: 0
  font-size: 12px

.owner-tag
  left: 0
  bottom: 0
  height: 20px
  border: 1.5px solid #FFDE67
  color: #FFDE67
  background: #0A0928E5
  border-radius: 10px
  font-size: 10px
  font-weight: bold
  ::v-deep
    .icon
      path
        stroke: #FFDE67

.social-space-card
  border-radius: 12px
  background: #FFF
  padding: 12px
  min-height: 200px
  .button
    margin: 0 !important
  &.vertical
    height: 276px
    padding: 10px
    flex-direction: column
    .wrp, .wrp-2
      width: 100% !important
      margin: 0 !important
    .image
      height: 130px
    .name
      margin-top: 5px !important
      font-size: 14px !important
    .description
      font-size: 10px !important


.image
  background: #FFC354
  width: 100%
  height: 100%
  border-radius: 12px
  overflow: hidden
  img
    height: 100%
    width: 100%
    object-fit: cover
    aspect-ratio: 1/1

.name
  font-weight: 700
  font-size: 16px
  span
    text-overflow: ellipsis
    width: 100%
    max-height: 40px
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden

.description
  span
    text-overflow: ellipsis
    width: 100%
    height: calc(100% - 110px)
    display: -webkit-box
    -webkit-line-clamp: 4
    -webkit-box-orient: vertical
    overflow: hidden
.actions
  *
    color: #214163 !important
    font-size: 12px
.description
  font-size: 12px
.join-btn
  width: 100%
  --width: 100%
  --height: 24px
  --background: linear-gradient(90deg, rgba(126, 224, 255, 0.4) 0%, rgba(95, 169, 238, 0) 74%), #A83CFF
  border-radius: 6px
  font-size: 12px
  opacity: 1 !important
  text-transform: unset !important
  &.joined
    --background: transparent
    border: 1.5px solid #43B701
    color: #43B701
  &.requested
    opacity: 0.7 !important

.tag
  top: 0
  min-width: 90px
  padding-left: 4px
  padding-right: 4px
  height: 20px
  border: 1.5px solid #7AD7FF
  color: #7AD7FF
  background: #0A0928E5
  font-size: 10px
  font-weight: bold
  border-radius: 10px
</style>
